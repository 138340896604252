/** @format */

import {propertyMap} from 'model-mapper';
import {Moment} from 'moment';
import {EmbededContractor} from './embeded-contractor';
import {EmbededOrganizationalUnit} from './embeded-organizational-unit';
import {EquipmentDocumentKind} from './equipment-document';
import {EmbededEquipmentKind} from './equipment-kind';
import {EmbededEquipmentManager} from './equipment-manager';
import {InspectionEquipmentStatus, InspectionState} from './inspection-status';
import {MetricValue} from './metrics';
import {EmbededRealEstateStructure} from './real-estate-structure';
import {EmbededUser} from './user';

export enum HypervisionProviderCodec {
  SYDERAL_ELEVATOR_MOTION_COUNT = 'elevator-motion-count',
  SYDERAL_PARKING_DOOR_STATE = 'parking-door-state',
  ADEUNIS_MOTION = 'motion',
  ADEUNIS_TEMP_V2 = 'tempv2',
  ADEUNIS_COMFORT = 'comfort',
  TREND_RAW_DATA = 'raw-data',
}

export enum HypervisionConnectivityKind {
  SOCKET = 'socket',
  LORA = 'lora',
  SIGFOX = 'sigfox',
  BOX = 'box',
}

export enum HypervisionProviderName {
  SYDERAL = 'syderal',
  ADEUNIS = 'adeunis',
  TREND = 'trend',
  SERENIS = 'serenis',
}

export enum EquipmentLinkKind {
  TREND = 'trend',
  SESIO_BOX = 'sesio-box',
  TRANSMITTER = 'transmitter',
}

export enum EquipmentStatus {
  OFFLINE = 'offline',
  ONLINE = 'online',
  WARNING = 'warning',
  NOFLOW = 'noflow',
  ND = 'nd',
}

export const EquipmentStatusColor = {
  [EquipmentStatus.ONLINE]: 'var(--ion-color-success)',
  [EquipmentStatus.OFFLINE]: 'var(--ion-color-danger)',
  [EquipmentStatus.WARNING]: 'var(--ion-color-warning)',
  [EquipmentStatus.NOFLOW]: 'var(--ion-color-noflow)',
  [EquipmentStatus.ND]: 'var(--ion-color-medium)',
};

export const EquipmentStatusIcon = {
  [EquipmentStatus.ONLINE]: 'check_circle',
  [EquipmentStatus.OFFLINE]: 'warning',
  [EquipmentStatus.WARNING]: 'error',
  [EquipmentStatus.NOFLOW]: 'link_off',
  [EquipmentStatus.ND]: 'close',
};

export const EquipmentStatusName = {
  [EquipmentStatus.ONLINE]: 'En service',
  [EquipmentStatus.OFFLINE]: 'En panne',
  [EquipmentStatus.WARNING]: 'En anomalie',
  [EquipmentStatus.NOFLOW]: 'Absence de flux',
  [EquipmentStatus.ND]: 'Non Supervisé',
};

export interface IEquipmentStatusOption {
  value: string;
  name: string;
  icon: {name: string; svg?: boolean};
  color: string;
}
[];

export const EquipmentStatusOptions: IEquipmentStatusOption[] = [
  {
    value: EquipmentStatus.ONLINE,
    name: EquipmentStatusName[EquipmentStatus.ONLINE],
    icon: {name: EquipmentStatusIcon[EquipmentStatus.ONLINE], svg: false},
    color: EquipmentStatusColor[EquipmentStatus.ONLINE],
  },
  {
    value: EquipmentStatus.OFFLINE,
    name: EquipmentStatusName[EquipmentStatus.OFFLINE],
    icon: {name: EquipmentStatusIcon[EquipmentStatus.OFFLINE], svg: false},
    color: EquipmentStatusColor[EquipmentStatus.OFFLINE],
  },
  {
    value: EquipmentStatus.WARNING,
    name: EquipmentStatusName[EquipmentStatus.WARNING],
    icon: {name: EquipmentStatusIcon[EquipmentStatus.WARNING], svg: false},
    color: EquipmentStatusColor[EquipmentStatus.WARNING],
  },
  {
    value: EquipmentStatus.NOFLOW,
    name: EquipmentStatusName[EquipmentStatus.NOFLOW],
    icon: {name: EquipmentStatusIcon[EquipmentStatus.NOFLOW], svg: false},
    color: EquipmentStatusColor[EquipmentStatus.NOFLOW],
  },
  {
    value: EquipmentStatus.ND,
    name: EquipmentStatusName[EquipmentStatus.ND],
    icon: {name: EquipmentStatusIcon[EquipmentStatus.ND], svg: false},
    color: EquipmentStatusColor[EquipmentStatus.ND],
  },
];

export enum SignatureFlowState {
  OK = 'ok',
  NOFLOW = 'noflow',
  // NOFLOW_4G = 'noflow-4g',
  // NOFLOW_TRANSMITTER = 'noflow-transmitter',
  // NOFLOW_SESIOBOX = 'noflow-sesiobox',
}

export const SignatureFlowStateOptions = [
  {value: SignatureFlowState.OK, name: 'OK', color: 'var(--ion-color-success)'},
  {value: SignatureFlowState.NOFLOW, name: 'Absence de flux', color: 'var(--ion-color-noflow)'},
  // { value: SignatureFlowState.NOFLOW_4G, name: 'Absence de flux 4G', color: 'var(--ion-color-noflow)' },
  // {
  //   value: SignatureFlowState.NOFLOW_TRANSMITTER,
  //   name: 'Absence de flux Transmetteur',
  //   color: 'var(--ion-color-noflow)',
  // },
  // { value: SignatureFlowState.NOFLOW_SESIOBOX, name: 'Absence de flux sésio Box', color: 'var(--ion-color-noflow)' },
];

export enum SignatureStatus {
  ONLINE = 'online',
  SUSPICIOUS = 'suspicious',
  OFFLINE = 'offline',
  ND = 'nd',
}

export const SignatureStatusColor = {
  [SignatureStatus.ONLINE]: 'var(--ion-color-success)',
  [SignatureStatus.SUSPICIOUS]: 'var(--ion-color-warning)',
  [SignatureStatus.OFFLINE]: 'var(--ion-color-danger)',
  [SignatureStatus.ND]: 'var(--ion-color-medium)',
};

export const SignatureStatusOptions = [
  {
    value: SignatureStatus.ONLINE,
    name: 'En service',
    color: SignatureStatusColor[SignatureStatus.ONLINE],
  },
  {
    value: SignatureStatus.SUSPICIOUS,
    name: 'Suspicion de panne',
    color: SignatureStatusColor[SignatureStatus.SUSPICIOUS],
  },
  {
    value: SignatureStatus.OFFLINE,
    name: 'En panne',
    color: SignatureStatusColor[SignatureStatus.OFFLINE],
  },
  {value: SignatureStatus.ND, name: '-', color: SignatureStatusColor[SignatureStatus.ND]},
];

export enum RIVPEvent {
  OK = 'ok',
  WORK_PENDING = 'work-pending',
  DISASTER = 'disaster',
}

export const RIVPEventOptions = [
  {value: RIVPEvent.WORK_PENDING, name: 'En travaux', color: 'var(--ion-color-warning)'},
  {value: RIVPEvent.DISASTER, name: 'Sinistre', color: 'var(--ion-color-warning)'},
  {value: RIVPEvent.OK, name: '-', color: 'var(--ion-color-medium)'},
];

export enum TestState {
  OK = 0,
  KO = 1,
}

export class LastInspection {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap({type: 'Moment'})
  public reportDate: Moment;

  @propertyMap()
  public reportStatus: InspectionEquipmentStatus;
}

export class NextInspection {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap({type: 'Moment'})
  public activeAt: Moment;

  @propertyMap({type: 'Moment'})
  public deadline: Moment;
}

export class HypervisionProvider {
  @propertyMap()
  public name: HypervisionProviderName;

  @propertyMap()
  public description: string;

  @propertyMap()
  public codec: HypervisionProviderCodec;

  @propertyMap()
  public productName: string;
}

export class HypervisionConnectivityIdentifier {
  @propertyMap()
  public devEUI: string;

  @propertyMap()
  public appEUI: string;

  @propertyMap()
  public appKEY: string;

  @propertyMap()
  public serialNumber: string;

  @propertyMap()
  public objeniousDeviceId: number;
}

export class HypervisionConnectivity {
  @propertyMap()
  public kind: HypervisionConnectivityKind;

  @propertyMap({type: HypervisionConnectivityIdentifier})
  public identifiers: HypervisionConnectivityIdentifier;
}

export class HypervisionConfiguration {
  @propertyMap({type: HypervisionProvider})
  public provider: HypervisionProvider;

  @propertyMap({type: HypervisionConnectivity})
  public connectivity: HypervisionConnectivity;
}

export class Transmitter {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public label: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public ipAddress: string;

  @propertyMap()
  public lineNumber: string;

  @propertyMap()
  public state: number;
}

export enum EquipmentTagType {
  MANUAL = 'manual',
  BARCODE_1d = 'barcode-1d',
  BARCODE_2d = 'barcode-2d',
  QRCODE = 'qrcode',
  NFC = 'nfc',
  RFID = 'rfid',
}

export class EquipmentPosition {
  @propertyMap()
  public x: number;

  @propertyMap()
  public y: number;
}

export class EquipmentControl {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public options: string[];
}

export class EquipmentFile {
  @propertyMap({source: '_id'})
  public id: any;

  @propertyMap()
  public kind: EquipmentDocumentKind;

  @propertyMap()
  public filename: string;

  @propertyMap()
  public title: string;

  @propertyMap()
  public description: string;

  @propertyMap({type: 'Moment'})
  public date: Moment;

  @propertyMap()
  public path: string;
}

export class EquipmentContractors {
  @propertyMap({type: EmbededContractor})
  public maintenance: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public telemonitor?: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public fitter?: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public study?: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public control?: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public hypervisor?: EmbededContractor;

  @propertyMap({type: EmbededContractor})
  public supervisor?: EmbededContractor;
}

export class EquipmentNetworkActivity {
  @propertyMap({type: 'Moment'})
  public lastFrameDate: Moment;

  @propertyMap({type: 'Moment'})
  public lastActivityDate: Moment;
}

export class EquipmentIntent {
  @propertyMap()
  public operationCount: number;

  @propertyMap()
  public eventCount: number;

  @propertyMap()
  public lastEvent: string;
}

export class Equipment {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap({type: EmbededEquipmentKind})
  public kind: EmbededEquipmentKind;

  @propertyMap()
  public code: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public label: string;

  @propertyMap()
  public comment: string;

  @propertyMap({type: 'Moment'})
  public commissioningDate: Moment;

  @propertyMap({type: 'Moment'})
  public modernizationDate?: Date;

  @propertyMap()
  public model?: string;

  @propertyMap()
  public brand?: string;

  @propertyMap({type: EmbededOrganizationalUnit})
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({type: EmbededRealEstateStructure})
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap()
  public realEstateStructureId: string;

  @propertyMap({type: EquipmentPosition})
  public position: EquipmentPosition;

  @propertyMap()
  public address: string;

  @propertyMap()
  public postalCode: string;

  @propertyMap()
  public location: string;

  @propertyMap()
  public geolocation: {
    type: 'Point';
    coordinates: [number, number];
  };

  @propertyMap()
  public linked: boolean;

  @propertyMap()
  public linkKind: string;

  @propertyMap()
  public supervised: boolean;

  @propertyMap()
  public propagateSupervision: boolean;

  @propertyMap()
  public status: EquipmentStatus;

  @propertyMap({type: 'Moment'})
  public statusDate: Moment;

  @propertyMap()
  public rivpEvent: string;

  @propertyMap({type: 'Moment'})
  public rivpEventDate: Moment;

  @propertyMap()
  public contractorEvent: string;

  @propertyMap({type: 'Moment'})
  public contractorEventDate: Moment;

  @propertyMap()
  public contractorOperationCount: number;

  @propertyMap()
  maintenance: string;

  @propertyMap({type: Date})
  maintenanceDate: Date;

  @propertyMap()
  public supervisorEventCount: number;

  @propertyMap()
  public lastSupervisorEventName: string;

  @propertyMap({type: 'Moment'})
  public lastSupervisorEventDate: Moment;

  @propertyMap()
  public signatureMotionAverage: number;

  @propertyMap()
  public signatureSettingName: string;

  @propertyMap()
  public signatureStatus: SignatureStatus;

  @propertyMap({type: 'Moment'})
  public signatureStatusDate: Moment;

  @propertyMap()
  public signatureFlowState: SignatureFlowState;

  @propertyMap({type: 'Moment'})
  public signatureFlowStateDate: Moment;

  @propertyMap({type: 'Moment'})
  public lastSensorDataDate: Moment;

  @propertyMap({type: 'Moment'})
  public lastSensorDataWithValueDate: Moment;

  @propertyMap()
  public reportCount: number;

  @propertyMap({type: 'Moment'})
  public lastReportDate: Moment;

  @propertyMap()
  public lotNumber: string;

  @propertyMap({type: EquipmentContractors})
  public contractors: EquipmentContractors;

  @propertyMap({type: EmbededEquipmentManager})
  public manager: EmbededEquipmentManager;

  @propertyMap()
  public externalReference: string;

  @propertyMap({type: 'Moment'})
  public contractStartDate: Moment;

  @propertyMap({type: 'Moment'})
  public contractEndDate: Moment;

  @propertyMap()
  public availabilityRate: number;

  @propertyMap()
  public serviceRate: number;

  @propertyMap()
  public rollingAvailabilityRate: number;

  @propertyMap()
  public failureCount: number;

  @propertyMap()
  public failureRate: number;

  @propertyMap()
  public controlRate: number;

  @propertyMap()
  public quality: number;

  @propertyMap()
  public state: number;

  @propertyMap()
  public anomalyCount: number;

  @propertyMap()
  public dangerCount: number;

  @propertyMap()
  public tagType: EquipmentTagType;

  @propertyMap()
  public tagCode: String;

  @propertyMap({type: [EquipmentFile]})
  public files: EquipmentFile[];

  @propertyMap({type: HypervisionConfiguration, default: null})
  public hypervisionConfiguration: HypervisionConfiguration;

  @propertyMap({type: MetricValue, default: {}})
  public metrics: MetricValue;

  @propertyMap()
  public metadata: any;

  @propertyMap({type: EquipmentNetworkActivity})
  public networkActivity: EquipmentNetworkActivity;

  @propertyMap({type: EquipmentIntent})
  public intent: EquipmentIntent;

  @propertyMap()
  public inspectionSate: InspectionState;

  @propertyMap({type: LastInspection})
  public lastInspection: LastInspection;

  @propertyMap({type: NextInspection})
  public nextInspection: NextInspection;

  @propertyMap()
  public _search: string;

  @propertyMap({default: false})
  public archived: boolean;

  @propertyMap({default: null, type: 'Moment'})
  public archivedChangedAt: Moment;

  @propertyMap({default: null})
  public archivedChangedBy: EmbededUser;

  public get picturesCount(): number {
    return (this.files || []).filter(f => f.kind === EquipmentDocumentKind.PICTURE).length;
  }

  public get documentsCount(): number {
    return (this.files || []).filter(f => f.kind !== EquipmentDocumentKind.PICTURE).length;
  }
}

export class EmbededEquipmentEmbededKind {
  @propertyMap()
  public category: string;
}

export class EmbededEquipment {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap({type: EmbededEquipmentKind})
  public kind: EmbededEquipmentKind;

  @propertyMap()
  public supervised: boolean;
}
